import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class TwoButtonService {
  constructor(public twoButtonAlert: AlertController) { }
  async showAlertTwo(header:string, message:string){
    const twoAlert = await this.twoButtonAlert.create({
      header,
      message,
      buttons: [
        {
          text: "Cancel",
          role: 'cancel',
          handler: () => {
            console.log("Confirm Cancel")
          
          }
        },
        {
          text: "Confirm",
          handler: () => {
            console.log("Confirmed")
          }
        }
      ]
    })
    await twoAlert.present()
  }
}
