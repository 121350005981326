import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class AlertServiceService {

  constructor(public alert: AlertController) { }
  async showAlert(header: string, message: string){
    const alert = await this.alert.create({
      header,
      message,
      buttons : ['Ok']
    })
    await alert.present()
  }
}
