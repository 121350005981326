import { Injectable } from '@angular/core';
interface User{
  nameUser: string;
  department: string;
  event: string;
}
@Injectable({
  providedIn: 'root'
})

export class UserService {
userData:User
  constructor() { }
  setData(userData:User){
    this.userData = userData
  }
  getData(){
    return this.userData.department+" "+this.userData.event
  }
  
}
